import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { Google, Facebook } from "grommet-icons";
import { Button } from "../../../components/Button/Button";
import { AuthProviderType } from "../../../core/models/user.model";
import { Image } from "../../../components/Image/Image";
import { Box } from "grommet";
import { oauthService } from "../../../core/services/oauth.service";
import naverLogo from "../../../assets/naver-icon.png";

type Props = {
  provider: AuthProviderType;
  className?: string;
  onError?: (err: any) => void;
  onClick?: (provider: AuthProviderType) => void;
  isDisabled?: boolean;
};

const providersMap: Record<
  AuthProviderType,
  {
    name: string;
    icon: JSX.Element;
  }
> = {
  google: {
    name: "Google",
    icon: <Google color="plain" />
  },
  naver: {
    name: "Naver",
    icon: <Image src={naverLogo} width="24px" />
  },
  facebook: {
    name: "Facebook",
    icon: <Facebook color="plain" />
  }
};

export const AuthButton = ({
  className,
  provider,
  onError,
  isDisabled,
  onClick
}: Props) => {
  const onClickCallback = useCallback(() => {
    const login = async () => {
      if (onClick) onClick(provider);
      const res = await oauthService.login(provider);

      if (res?.err && onError) {
        onError(res.err);
      }

      if (res?.authUrl) {
        window?.location.replace(res.authUrl);
      }
    };
    login();
  }, [provider]);

  const { name, icon } = providersMap[provider];
  const text = `Continue with ${name}`;
  return (
    <Box pad="xsmall">
      <Button
        className={classNames(className)}
        iconLeft={icon}
        isDisabled={isDisabled}
        onClick={onClickCallback}
      >
        {text}
      </Button>
    </Box>
  );
};
