import { useEffect, useState } from "react";
import { throttle } from "lodash";

type ScreenSize = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  width: number;
  height: number;
};

const MOBILE_BP = 480;
const TABLET_BP = 768;

const getScreenSize = (): ScreenSize => {
  const { innerWidth: width, innerHeight: height } = window;

  const isMobile = width <= MOBILE_BP;
  const isTablet = width > MOBILE_BP && width <= TABLET_BP;
  const isDesktop = width > TABLET_BP;

  return {
    width,
    height,
    isMobile,
    isTablet,
    isDesktop
  };
};

export const useScreenSize = (timeout: number = 200): ScreenSize => {
  const [screenSize, setScreenSize] = useState(getScreenSize);

  useEffect(() => {
    const onResize = throttle(() => {
      const screenSize = getScreenSize();
      setScreenSize(screenSize);
    }, timeout);

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return screenSize;
};
