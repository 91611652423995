import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Logo } from "../../components/Logo/Logo";
import { parseQuery } from "../../core/utils";
import { AuthButton } from "./AuthButton/AuthButton";
import { ErrorText } from "../../components/ErrorText/ErrorText";
import { NavigationBar } from "../NavigationBar/NavigationBar";
import { useScreenSize } from "../../hooks/use-screen-size";
import { Box } from "grommet"; // TODO double check footprint from this framework
import styles from "./SignUp.pcss";

type Props = {
  className?: string;
};

const subtitles = [
  "Extract colors from any picture",
  "Start with color, discover more"
];

export const SignUp = ({ className }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [providerError, setProviderError] = useState<{
    providerId?: string;
    code?: string;
  }>({});

  useEffect(() => {
    const { providerId, code } = parseQuery(window.location.href);
    setProviderError({ providerId, code });
  }, []);

  const { providerId, code } = providerError;

  const onAuthBtnClick = () => setIsLoading(prevState => !prevState);

  const { isDesktop } = useScreenSize();

  const onError = (err: any) => {
    setIsLoading(false);
    setError(err);
  };

  return (
    <div className={classNames(className, styles.container)}>
      <NavigationBar />

      <div className={styles.background} />

      <div className={styles.wrapper}>
        <Box
          pad={{
            horizontal: "large",
            vertical: "medium",
            bottom: "large"
          }}
          elevation={isDesktop ? "small" : "none"}
          className={styles.buttons}
        >
          <header className={styles.header}>
            <Logo className={styles.logo} />
            <div className={styles.text}>
              <h1 className={styles.title}>Welcome to Pictones!</h1>
              <span className={styles.subtitle}>{subtitles[0]}</span>
            </div>
          </header>

          {providerId && code && (
            <ErrorText>
              Error from {providerId}, code {code}
            </ErrorText>
          )}
          {isError && (
            <ErrorText>There was an error during request execution</ErrorText>
          )}

          <AuthButton
            provider="google"
            isDisabled={isLoading}
            onClick={onAuthBtnClick}
            onError={onError}
          />

          <AuthButton
            provider="facebook"
            isDisabled={isLoading}
            onClick={onAuthBtnClick}
            onError={onError}
          />

          <AuthButton
            provider="naver"
            isDisabled={isLoading}
            onClick={onAuthBtnClick}
            onError={onError}
          />
        </Box>
      </div>
    </div>
  );
};
