import { ApiService, apiService } from "./api.service";
import { User } from "../models/user.model";

class SessionService {
  public currentUser?: User;
  public isAuthenticated = false;

  constructor(private apiService: ApiService) {}

  async populate() {
    try {
      const user: User = await this.apiService.get("/user");
      this.setSession(user);
      return { user };
    } catch (err) {
      return { err };
    }
  }

  async destroy() {
    try {
      await this.apiService.post("/auth/logout");
      this.clearSession();
    } catch (err) {
      return { err };
    }
  }

  setSession(user: User) {
    this.currentUser = user;
    this.isAuthenticated = true;
  }

  clearSession() {
    delete this.currentUser;
    this.isAuthenticated = false;
  }
}

export const sessionService = new SessionService(apiService);
