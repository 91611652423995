import React, { useEffect, useState } from "react";
import styles from "./PaletteEditor.pcss";
import { paletteService } from "../../../core/services/palette.service";
import { NavigationBar } from "../../NavigationBar/NavigationBar";
import { ErrorText } from "../../../components/ErrorText/ErrorText";
import { Slider } from "../../../components/Slider/Slider";

export const PaletteEditor = props => {
  const paletteId = props.match.params.id;

  const [palette, setPalette] = useState({});
  const [paletteError, setPaletteError] = useState();
  useEffect(() => {
    const getPalette = async () => {
      const res = await paletteService.get(paletteId);
      if (res?.err) {
        setPaletteError(res.err);
      }
      if (res?.id) {
        setPalette(res);
      }
    };
    getPalette();
  }, []);

  const onColorsChange = async e => {
    const val = e.target.value;
    setPalette(prevState => ({
      ...prevState,
      colorsQuantity: val
    }));
    await paletteService.updateColorsQuantity(
      palette.id,
      palette.colorsQuantity
    );
  };

  const Colors = ({ variations, colors, colorsQuantity }) => {
    console.log(variations);
    if (!variations) return null;
    return (
      <div className={styles.colorsContainer}>
        {variations[colorsQuantity].map(variation => (
          <div key={variation} style={{ backgroundColor: colors[variation] }}>
            {colors[variation]}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <NavigationBar />
      <div className={styles.container}>
        {paletteError && (
          <ErrorText>We are having troubles finding this palette</ErrorText>
        )}
        <img className={styles.img} src={palette.imageUrl} />
        <span>Number of colors: {palette.colorsQuantity}</span>
        <Slider
          value={palette.colorsQuantity || 0}
          min={1}
          max={32}
          onChange={onColorsChange}
        ></Slider>
        {palette?.variations && <Colors {...palette} />}
      </div>
    </div>
  );
};
