import React from "react";
import classNames from "classnames";
import { NavigationBar } from "../NavigationBar/NavigationBar";
import { PaletteUploader } from "../Palette/Uploader/PaletteUploader";

type Props = {
  className?: string;
};

export const Home = ({ className }: Props) => {
  return (
    <div className={classNames(className)}>
      <NavigationBar />
      <PaletteUploader />
    </div>
  );
};
