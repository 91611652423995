import React from "react";
import { render } from "react-dom";
import "./index.pcss";
import { version } from "../package.json";
import { Main } from "./Main";

const env = process.env.NODE_ENV;

console.info({ env, version });
// TODO exclude from prod bundle this package
if (env === "development") require("react-devtools");

render(<Main />, document.getElementById("app"));
