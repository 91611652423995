import { useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { match } from "path-to-regexp";
import { SessionContext } from "../Main";
import { routesMap } from "../constants/routesMap";

const routes = Object.values(routesMap);

export const useRouteGuard = () => {
  const { user } = useContext(SessionContext);
  const history = useHistory();
  const { pathname } = useLocation();

  function authorize() {
    if (pathname) {
      const route = routes.find(({ path }) => match(path)(pathname));
      let isAuthorized = route?.role === user?.role;
      if (route.role === undefined) {
        isAuthorized = true;
      }
      if (!isAuthorized) {
        history.push(routesMap.home.path);
      }
    }
  }

  useEffect(authorize, [pathname]);
};
