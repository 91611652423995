import { RefObject, useEffect, useRef } from "react";

export const useClickOutside = <T extends Node>(
  onDetect: Function
): RefObject<T> => {
  const ref: RefObject<T> = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onDetect();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onDetect]);

  return ref;
};
