import React, { useState } from "react";
import classNames from "classnames";
import styles from "./PaletteUploader.pcss";
import { paletteService } from "../../../core/services/palette.service";
import { useHistory } from "react-router-dom";
import { validateFileSize } from "../../../core/utils";
import { ErrorText } from "../../../components/ErrorText/ErrorText";

const DEFAULT_COLORS_QUANTITY = 6;
const MAX_FILE_SIZE = 5; //in MB

export const PaletteUploader = ({ className }) => {
  const [fileSizeError, setFileSizeError] = useState(false);
  const fileInput = React.createRef();
  const history = useHistory();
  const onFileUpload = async () => {
    if (!validateFileSize(fileInput.current.files[0], MAX_FILE_SIZE)) {
      setFileSizeError(true);
      return;
    }
    const res = await paletteService.upload(
      fileInput.current.files[0],
      DEFAULT_COLORS_QUANTITY
    );
    if (res?.err) {
      console.log(res.err);
    }
    if (res?.id) {
      history.push(`/palette/${res.id}`);
    }
  };

  const onDropzoneClick = () => {
    fileInput.current.click();
  };

  return (
    <div className={classNames(className, styles.container)}>
      <div onClick={onDropzoneClick} className={styles.imgPlaceholder}></div>
      {fileSizeError && <ErrorText>Max file size is 5 MB</ErrorText>}
      <span>Extract colors from any pictures</span>
      <label className={styles.fileLabel}>
        Upload picture
        <input
          className={styles.fileInput}
          type="file"
          accept="image/*"
          ref={fileInput}
          onChange={onFileUpload}
        ></input>
      </label>
    </div>
  );
};
