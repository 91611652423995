import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { NavigationBar } from "../NavigationBar/NavigationBar";
import { paletteService } from "../../core/services/palette.service";
import { PaginationPayload } from "../../core/models/pagination.model";
import {
  Colors,
  getPaletteColors,
  Palette
} from "../../core/models/palette.model";
import styles from "./UserPalettes.pcss";
import { Box, Grid, Heading } from "grommet";
import { Link } from "react-router-dom";
import { routesMap } from "../../constants/routesMap";
import { Image } from "../../components/Image/Image";

type Props = {
  className?: string;
};

const useUserPalettesFetch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [palettes, setPalettes] = useState<Palette[]>([]);
  const [pagination, setPagination] = useState<PaginationPayload>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { palettes, pagination } = await paletteService.getAll();
      if (palettes) {
        setPalettes(palettes);
        setPagination(pagination);
      }
      setIsLoading(false);
    })();
  }, []);

  return {
    isLoading,
    palettes
  };
};

const ColorBlock = ({ colors }: { colors: Colors }) => {
  return (
    <div className={styles.colorsBlock}>
      {colors.map(color => (
        <Box className={styles.color} background={color} />
      ))}
    </div>
  );
};

export const UserPalettes = ({ className }: Props) => {
  const { palettes, isLoading } = useUserPalettesFetch();

  return (
    <div className={classNames(className, styles.container)}>
      <NavigationBar />

      <div className={styles.header}>
        <Heading size="small" margin="small">
          My palettes
        </Heading>
      </div>

      {isLoading ? (
        <span>loading ...</span>
      ) : (
        <section className={styles.palettes}>
          <Grid columns="medium" gap="medium">
            {palettes.map(palette => (
              <Link className={styles.palette} to={routesMap.home.path}>
                <Image className={styles.paletteImage} src={palette.imageUrl} />

                <ColorBlock colors={getPaletteColors(palette)} />

                <Heading className={styles.paletteTitle} level="4">
                  {palette.id}
                </Heading>
              </Link>
            ))}
          </Grid>
        </section>
      )}
    </div>
  );
};
