import { ApiService, apiService } from "./api.service";
import { AuthProviderType } from "../models/user.model";

class OauthService {
  constructor(private apiService: ApiService) {}

  async login(providerId: AuthProviderType) {
    try {
      const { authUrl } = await this.apiService.get(
        `/auth/${providerId}/login`
      );
      return { authUrl };
    } catch (err) {
      return { err };
    }
  }
}

export const oauthService = new OauthService(apiService);
