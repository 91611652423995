import React from "react";

type Props = {
  className?: string;
  src: string;
  width?: number | string;
};

export const Image = (props: Props) => {
  const { src, width, className } = props;
  return (
    <div className={className} style={{ width: width, height: width }}>
      <img src={src} style={{ width: "100%", height: "auto" }} />
    </div>
  );
};
