import { PaginationPayload } from "./pagination.model";

export type Color = string;
export type Colors = Color[];

export interface Palette {
  id: string;
  imageUrl: string;
  colors: Record<number, Color>;
  variations: number[][];
  colorsQuantity: number;
  status: string;
  createdAt: string;
}

export type PalettesPayload = {
  pagination: PaginationPayload;
  palettes: Palette[];
};

export const getPaletteColors = (palette: Palette): Colors => {
  return palette.variations[palette.colorsQuantity].map(
    idx => palette.colors[idx]
  );
};
