import Axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { camelizeDeep } from "../utils";

export type HttpError = {
  status: string;
  code: number;
  message: string;
};

export interface IHttpErrorConstructor {
  new (code: number, status: string, message?: string): HttpError;
  readonly prototype: Error;
}

const HttpError: IHttpErrorConstructor = class HttpError extends Error {
  code: number;
  status: string;

  constructor(code: number, status: string, message?: string) {
    super(message);
    this.code = code;
    this.status = status;
  }
};

function handleError(error: AxiosError) {
  const { response } = error;
  const { status } = response;
  const { message, code } = response.data;
  const httpError = new HttpError(code, status, message);
  return Promise.reject(httpError);
}

function handleSuccess(res: AxiosResponse) {
  const { data } = res;
  return camelizeDeep(data);
}

const axios: AxiosInstance = Axios.create({
  baseURL: process.env.API_URL,
  timeout: 10000,
  withCredentials: true
});

axios.interceptors.response.use(handleSuccess, handleError);

type DebugMethod = <T>(data: T, timeout?: number) => Promise<T>;

interface DebugMethods {
  respond: DebugMethod;
  reject: DebugMethod;
}

const debug: DebugMethods = {
  respond<T>(data: T, timeout: number = 1000): Promise<T> {
    return new Promise(res => setTimeout(() => res(data), timeout));
  },
  reject<T>(data: T, timeout: number = 1000): Promise<T> {
    return new Promise((_, rej) =>
      setTimeout(() => rej({ response: { data } }), timeout)
    );
  }
};

export interface ApiService extends AxiosInstance, DebugMethods {}

// Object spread only includes objects’ own, enumerable properties.
// Basically, that means we lose methods when we spread instances of an object
export const apiService: ApiService = Object.assign({}, axios, debug);
