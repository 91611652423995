import { ApiService, apiService } from "./api.service";
import { Pagination } from "../models/pagination.model";
import { PalettesPayload } from "../models/palette.model";

class PaletteService {
  constructor(private apiService: ApiService) {}

  async get(paletteId: number) {
    try {
      return await this.apiService.get(`/palettes/${paletteId}`);
    } catch (err) {
      return { err };
    }
  }

  async getAll(pagination: Pagination = { page: 1, limit: 20 }) {
    return (await this.apiService.get(
      `/palettes?page=${pagination.page}&limit=${pagination.limit}`
    )) as PalettesPayload;
  }

  async upload(img: Blob, colorQuantity: number) {
    let formData = new FormData();
    formData.append("image", img);
    formData.append("colors_quantity", String(colorQuantity));

    try {
      // type response
      return await this.apiService.post("/palettes", formData);
    } catch (err) {
      return { err };
    }
  }

  async updateColorsQuantity(paletteId, colorsQuantity) {
    try {
      return await this.apiService.put(`/palettes/${paletteId}`, {
        colors_quantity: colorsQuantity
      });
    } catch (err) {
      return { err };
    }
  }
}

export const paletteService = new PaletteService(apiService);
