import { UserRole } from "../core/models/user.model";

export const routes = {
  home: { path: "/" },
  signUp: { path: "/sign-up", role: 0 },
  paletteEditor: { path: "/palette/:id" },
  userPalettes: { path: "/palettes", role: 1 }
};

export const routesMap: Record<
  keyof typeof routes,
  { path: string; role?: UserRole }
> = Object.assign(routes);
