import * as React from "react";
import classNames from "classnames";
import { Button as KitButton } from "grommet";
import styles from "./Button.pcss";

export interface Props {
  className?: string;
  isDisabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  children?: string | string[];
  iconLeft?: JSX.Element;
}

export const Button: React.FunctionComponent<Props> = props => {
  const { className, isDisabled, onClick, children, iconLeft } = props;

  return (
    <KitButton
      className={classNames(className, styles.button)}
      disabled={isDisabled}
      onClick={onClick}
      icon={iconLeft}
      label={children}
    />
  );
};
