import { useCallback, useEffect, useState } from "react";
import { sessionService } from "../core/services/session.service";
import { User } from "../core/models/user.model";

export type SessionContextType = {
  user?: User;
  login: Function;
  logout: Function;
};

export const useSession = (): SessionContextType => {
  const [user, setUser] = useState<User | undefined>(
    sessionService.currentUser
  );

  const login = useCallback(async () => {
    sessionService.populate().then(({ user }) => {
      if (user) setUser(user);
    });
  }, []);

  const logout = useCallback(async () => {
    await sessionService.destroy();
    await login();
  }, []);

  useEffect(() => {
    if (!sessionService.isAuthenticated) login();
  }, []);

  // TODO break handlers and data into different context / consider observables user in services
  return { user, login, logout };
};
