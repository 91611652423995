import React, { useContext, useRef, useState } from "react";
import classNames from "classnames";
import { Anchor, Avatar, Box, Drop, Text } from "grommet";
import { User as UserIcon, Power as PowerIcon } from "grommet-icons";
import { useClickOutside } from "../../hooks/use-click-outside";
import { SessionContext } from "../../Main";
import styles from "./UserMenu.pcss";

type Props = {
  className?: string;
};

export const UserMenu = ({ className }: Props) => {
  const { user, logout } = useContext(SessionContext);

  const avatarRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  const menuRef = useClickOutside<HTMLDivElement>(closeMenu);

  const onLogOutButtonClick = () => logout();

  // TODO consider user model getters
  const name = user?.name || user?.id;
  const image = user?.photoUrl;
  const isRegistered = user?.role;

  if (!isRegistered) return null;

  return (
    <div className={classNames(className, styles.container)}>
      <Text className={styles.username} size="small" color="brand">
        {name}
      </Text>
      <div className={styles.avatar} ref={avatarRef} onClick={toggleMenu}>
        {image ? (
          <Avatar src={image} size="small" background="brand" />
        ) : (
          <Avatar size="small" background="brand">
            <UserIcon size="small" />
          </Avatar>
        )}
      </div>
      {isOpen && avatarRef.current && (
        <Drop
          align={{ top: "bottom", right: "right" }}
          target={avatarRef.current}
        >
          <Box pad="small" ref={menuRef}>
            <Anchor
              icon={<PowerIcon />}
              label="Log out"
              onClick={onLogOutButtonClick}
            />
          </Box>
        </Drop>
      )}
    </div>
  );
};
