import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import "./Logo.pcss";

type Props = {
  className?: string;
  href?: string;
};

export const Logo = ({ className, href = "/" }: Props) => {
  return (
    <Link className={classNames(className)} to={href}>
      <svg width="65" height="73" xmlns="http://www.w3.org/2000/svg">
        <g fill="#FF435A" fillRule="evenodd">
          <path d="M32.5 65C14.55 65 0 50.45 0 32.5S14.55 0 32.5 0 65 14.55 65 32.5 50.45 65 32.5 65zm0-17.5c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z" />
          <path d="M0 32.839h17.266v39.609H0z" />
        </g>
      </svg>
    </Link>
  );
};
