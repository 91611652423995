import React, { useContext, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Logo } from "../../components/Logo/Logo";
import styles from "./NavigationBar.pcss";
import { UserMenu } from "../UserMenu/UserMenu";
import { SessionContext } from "../../Main";
import { routesMap } from "../../constants/routesMap";

type Props = {
  className?: string;
};

export const NavigationBar = ({ className }: Props) => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const { user } = useContext(SessionContext);

  const isGuest = Boolean(user && !user.role);
  const isRegistered = Boolean(user && user.role);

  return (
    <header className={classNames(className, styles.navigationBar)}>
      <div className={styles.navigationBarWrapper}>
        <Logo href={routesMap.home.path} className={styles.logo} />
        {isGuest && <Link to="/sign-up">Sign-up</Link>}

        <div className={styles.rightSection}>
          {isRegistered && (
            <Link className={styles.menuItem} to={routesMap.userPalettes.path}>
              My Palettes
            </Link>
          )}
          <UserMenu className={styles.menuItem} />
        </div>
      </div>
    </header>
  );
};
