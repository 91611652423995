export function parseQuery(
  urlString: string
): Record<string, string | undefined> {
  const url = new URL(urlString);
  let params: Record<string, string> = {};
  url.searchParams.forEach((v, k) => (params[k] = v));
  return params;
}

export function camelizeDeep(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((el: any) => camelizeDeep(el));
  }

  if (!isObject(obj)) return obj;

  return Object.entries(obj).reduce((a, [k, v]) => {
    a[toCamel(k)] = camelizeDeep(v);
    return a;
  }, {} as Record<string, any>);
}

export function validateFileSize(file, maxSize) {
  const fileSize = file.size / 1024 / 1024; //calculate file size in MB
  return fileSize < maxSize;
}

function toCamel(str: string): string {
  return str.replace(/([-_][a-z])/gi, match => {
    return match
      .toUpperCase()
      .replace("-", "")
      .replace("_", "");
  });
}

function isObject(val: any): boolean {
  return val != null && typeof val === "object" && Array.isArray(val) === false;
}
