import * as React from "react";
import classNames from "classnames";
import { RangeInput as KitSlider } from "grommet";
import styles from "./Slider.pcss";

export const Slider = props => {
  const { className, isDisabled, onChange, min, max, value } = props;

  return (
    <KitSlider
      className={classNames(className, styles.slider)}
      disabled={isDisabled}
      onChange={onChange}
      step={1}
      min={min}
      max={max}
      value={value}
    />
  );
};
