import React from "react";
import { Box, Text } from "grommet";

type Props = {
  className?: string;
  children: string | string[];
};

export const ErrorText = ({ children }: Props) => {
  return (
    <Box pad="xsmall">
      <Text color="status-error" alignSelf="center">
        {children}
      </Text>
    </Box>
  );
};
