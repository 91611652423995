import React, { createContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { SignUp } from "./modules/SignUp/SignUp";
import { Home } from "./modules/Home/Home";
import { User } from "./core/models/user.model";
import { useSession, SessionContextType } from "./effects/use-session";
import { useRouteGuard } from "./effects/use-route-guard";
import { PaletteEditor } from "./modules/Palette/Editor/PaletteEditor";
import { routesMap } from "./constants/routesMap";
import { UserPalettes } from "./modules/UserPalettes/UserPalettes";

export const SessionContext = createContext<SessionContextType>(
  {} as SessionContextType
);

const App = ({ children }: { children: JSX.Element[] }) => {
  useRouteGuard();
  return <>{children}</>;
};

export const Main = () => {
  const session = useSession();
  return (
    <SessionContext.Provider value={session}>
      <Router>
        <App>
          <Route exact path="/" component={Home} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/palette/:id" component={PaletteEditor} />
          <Route path={routesMap.userPalettes.path} component={UserPalettes} />
        </App>
      </Router>
    </SessionContext.Provider>
  );
};
